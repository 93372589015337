import React from "react";
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonButtons,
  IonMenuButton,
  IonGrid,
  IonItem,
  IonIcon,
  IonLabel,
} from "@ionic/react";
import { connect } from "../../data/connect";
import Map from "../../components/Map";
import { Location } from "../../models/Location";
import * as selectors from "../../data/selectors";
import "./contact.scss";
import { call, mail, logoWhatsapp, timer } from "ionicons/icons";
import { FaWaze } from "react-icons/fa";
interface OwnProps {}

interface StateProps {
  locations: Location[];
  mapCenter: Location;
}

interface DispatchProps {}

interface ContactViewProps extends OwnProps, StateProps, DispatchProps {}

const Contact: React.FC<ContactViewProps> = ({ locations, mapCenter }) => {
  return (
    <IonPage id="Contact">
      <IonHeader translucent={true}>
        <IonToolbar className="HeaderPage">
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonTitle>Contacto</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen={true}>
        <IonGrid fixed>
          <IonToolbar>
            <IonTitle className="numberContact">Números de Contacto</IonTitle>
          </IonToolbar>
          <IonItem className="labelContacts">
            <IonIcon icon={call} slot="start" color="primary" />
            <IonLabel>
              <a href="tel:+56722230150">722230150</a>
            </IonLabel>
          </IonItem>
          <IonItem className="labelContacts">
            <IonIcon icon={call} slot="start" color="primary" />
            <IonLabel>
              <a href="tel:+56722230059">722230059</a>
            </IonLabel>
          </IonItem>
          <IonItem className="labelContacts">
            <IonIcon icon={logoWhatsapp} slot="start" color="success" />
            <IonLabel>
              <a href="https://wa.me/56968088316">+56968088316</a>
            </IonLabel>
          </IonItem>
          <IonItem className="labelContacts">
            <IonIcon icon={mail} slot="start" color="secondary" />
            <IonLabel>
              <a href="mailto:tesindical@codelco.cl">tesindical@codelco.cl</a>
            </IonLabel>
          </IonItem>
          <IonItem className="labelContacts">
            <IonIcon icon={timer} slot="start" color="instagram" />
            <IonLabel>Lun. – Vie. (9:00 – 13:30 y 15:00 – 19:30)</IonLabel>
          </IonItem>
          <IonItem className="labelContacts">
            <div className="iconContact">
              <FaWaze size={"20px"}></FaWaze>
            </div>
            <IonLabel>
              <a
                href="https://www.waze.com/ul?ll=-34.17083870%2C-70.74569860&navigate=yes"
                title=""
              >
                O'carrol 740, Rancagua
              </a>
            </IonLabel>
          </IonItem>
        </IonGrid>

        <IonGrid fixed>
          <IonToolbar>
            <IonTitle>Ubicación</IonTitle>
          </IonToolbar>
          <Map locations={locations} mapCenter={mapCenter} />
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    locations: state.data.locations,
    mapCenter: selectors.mapCenter(state),
  }),
  component: Contact,
});
