import React, { useContext } from "react";
import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonPage,
  IonButtons,
  IonMenuButton,
  IonTitle,
} from "@ionic/react";
import "./styles.scss";
import { Document } from "../../providers/Documents";
import { DownloadOutlined } from "@ant-design/icons";
import { Table } from "antd";
import { Card } from "antd";
interface MiselaniunProps {}

const About: React.FC<MiselaniunProps> = () => {
  const { documentsForm } = useContext(Document);
  const columnsRetiro = [
    {
      title: "Comisión",
      dataIndex: "comision",
    },
    {
      title: "Titular",
      dataIndex: "titular",
    },

    {
      title: "Remplazante",
      dataIndex: "remplazante",
    },
  ];
  const dataRetiro = [
    {
      key: "1",
      comision: "Desarrollo Carrera Laboral",
      titular: "David Olea L",
      remplazante: "Sergio Clavero R.",
    },
    {
      key: "2",
      comision: "Desarrollo Carrera Sindical",
      titular: "Sergio Clavero R.",
      remplazante: "Marcelo Pérez N.",
    },
    {
      key: "3",
      comision: "Ergonomia",
      titular: "Marcelo Pérez N.",
      remplazante: "Ricardo Orrego S.",
    },
    {
      key: "4",
      comision: "Previsional",
      titular: "Sergio Clavero R.",
      remplazante: "Gonzalo Aranguiz S.",
    },
    {
      key: "5",
      comision: "Convenio Colectivo",
      titular: "Sergio Clavero R.",
      remplazante: "David Olea L.",
    },
    {
      key: "6",
      comision: "Diversidad de Género",
      titular: "Marcelo Pérez N.",
      remplazante: "Ricardo Orrego S.",
    },
    {
      key: "7",
      comision: "Competividad y Productividad",
      titular: "David Olea L.",
      remplazante:"Sergio Clavero R ",
    },
    {
      key: "8",
      comision: "Becas",
      titular: "Sergio Clavero R.",
      remplazante: "Gonzalo Aranguiz S.",
    },
    {
      key: "9",
      comision: "Seguridad",
      titular: "David Olea L.",
      remplazante: "Gonzalo Aranguiz S.",
    },
    {
      key: "10",
      comision: "Habitacional",
      titular: "Gonzalo Aranguiz S.",
      remplazante: "David Olea L.",
    },
    {
      key: "11",
      comision: "Salud",
      titular: "Ricardo Orrego S.",
      remplazante: "David Olea L.",
    },
    {
      key: "12",
      comision: "Quejas",
      titular: "Ricardo Orrego S.",
      remplazante: "Marcelo Pérez N.",
    },
    {
      key: "13",
      comision: "Transporte",
      titular: "Gonzalo Aranguiz S.",
      remplazante: "David Olea L.",
    },
    {
      key: "14",
      comision: "Sustentabilidad",
      titular: "Ricardo Orrego S.",
      remplazante:  "Marcelo Pérez N.",
    },
    {
      key: "15",
      comision: "Transformación Cultural",
      titular: "Marcelo Pérez N.",
      remplazante:  "Sergio Clavero R.",
    },
    {
      key: "16",
      comision: "Covid",
      titular:  "David Olea L.",
      remplazante:   "Ricardo Orrego S.",
    },
    {
      key: "17",
      comision: "Nuevo Nivel Mina",
      titular: "Gonzalo Aranguiz S.",
      remplazante: "David Olea L.",
    },
  ];

  return (
    <IonPage id="about-page" className="comisiones">
      <IonHeader translucent={true}>
        <IonToolbar className="HeaderPage">
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonTitle>Comisiones</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent forceOverscroll fullscreen>
        <Table
          rowClassName={(record, index) =>
            index % 2 === 0 ? "table-row-light" : "table-row-dark"
          }
          columns={columnsRetiro}
          dataSource={dataRetiro}
          pagination={false}
        />
      </IonContent>
    </IonPage>
  );
};

export default React.memo(About);
