import React, { useState, useContext } from "react";
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonButtons,
  IonMenuButton,
} from "@ionic/react";
import "./styles.scss";

import { Form, Input, Button, Checkbox, Alert } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import Firebase from "../../providers/firebase";
import { useHistory } from "react-router-dom";
import _get from "lodash/get";
import { Auth } from "../../providers/AuthContext";

interface LoginProps {}

const Login: React.FC<LoginProps> = () => {
  const history = useHistory();
  const {
    usuario,
    setUsuario,
    setAuthenticated,
    setUserBenefits,
    setBenefits,
  } = useContext(Auth);

  const [errors, setErrors] = useState("");
  const onFinish = async (values: any) => {
    if (values.username && values.password) {
      await correoClave(values.username, values.password);
    }
  };
  const correoClave = async (usuario: string, clave: string) => {
    Firebase.auth()
      .setPersistence(Firebase.auth.Auth.Persistence.SESSION)
      .then(function () {
        // Existing and future Auth states are now persisted in the current
        // session only. Closing the window would clear any existing state even
        // if a user forgets to sign out.
        // ...
        // New sign-in will be persisted with session persistence.
        return Firebase.auth()
          .signInWithEmailAndPassword(usuario, clave)
          .then(async (result) => {
            let uid = _get(result, "user.uid", "");
            let userInfo = await Firebase.firestore()
              .collection("users")
              .doc(uid);

            try {
              let doc = await userInfo.get();
              setUsuario(doc.data());
              let userData = doc.data();
              if (
                userData &&
                (userData.role === "IHuvDeIQ1TIhkcFfYpUU" ||
                  userData.role === "AHgJx9zLxD2DyOz1b9ix")
              ) {
                //roles IHuvDeIQ1TIhkcFfYpUU - AHgJx9zLxD2DyOz1b9ix
                setAuthenticated(true);

                let userBenefitsRef = await Firebase.firestore().collection(
                  "registerBenefits"
                );

                let userBenefits = await userBenefitsRef
                  .where("user", "==", uid)
                  .get();
                let userBenefistsArray: Firebase.firestore.DocumentData[] = [];
                console.log("userBenefits", userBenefits);
                userBenefits.forEach(function (doc) {
                  let d = doc.data();
                  d.id = doc.id;
                  d.key = doc.id;
                  userBenefistsArray.push(d);
                });
                setUserBenefits(userBenefistsArray);
                let benefitsRef = await Firebase.firestore().collection(
                  "benefits"
                );
                let benefits = await benefitsRef.get();

                let benefitsArray: Firebase.firestore.DocumentData[] = [];

                benefits.forEach(function (doc) {
                  let b = doc.data();
                  b.id = doc.id;

                  benefitsArray.push(b);
                });
                setBenefits(benefitsArray);

                history.push("/tabs/myprofile", { direction: "none" });
              } else {
                setErrors(
                  "El usuario es válido, pero debe ser activado por el secretario del sindicato."
                );
              }
            } catch (e) {}
          })
          .catch((error) => {
            if (error.code === "auth/wrong-password") {
              setErrors("La contraseña ingresada es incorrecta");
              console.log("La contraseña es incorrecta");
            }
            if (error.code === "auth/user-not-found") {
              setErrors("El correo ingresado no se encuentra registrado");
              console.log("correo no registrado");
            }

            console.log(error.code);
          });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <IonPage id="login-page">
      <IonHeader>
        <IonToolbar className="HeaderPage">
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonTitle> INGRESAR </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="login-logo">
          <img src="assets/logo/logo.png" height="150" alt="Logo"></img>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <Form
                name="normal_login"
                className="login-form"
                initialValues={{ remember: true }}
                onFinish={onFinish}
              >
                <Form.Item
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese su usuario!",
                    },
                  ]}
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Usuario"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese su contraseña!",
                    },
                  ]}
                >
                  <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Contraseña"
                  />
                </Form.Item>

                <Form.Item>
                  <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox>Recordarme</Checkbox>
                  </Form.Item>

                  <a className="login-form-forgot" href="/tabs/forgot">
                    Olvido su contraseña
                  </a>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                  >
                    Ingresar
                  </Button>
                </Form.Item>
                {errors !== "" && (
                  <Form.Item>
                    <Alert message={errors} type="error" />
                  </Form.Item>
                )}
                <Form.Item>
                  o{" "}
                  <a href="/tabs/signup" className="signup-form-button">
                    Registrarse ahora!
                  </a>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Login;
