import React, { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Auth } from "../../providers/AuthContext";

interface LogoutProps {}

const Logout: React.FC<LogoutProps> = () => {
  const history = useHistory();

  const { setUsuario, isAuthenticated, setAuthenticated } = useContext(
    Auth
  );

  useEffect(() => {
    if (isAuthenticated) {
      setAuthenticated(false);
      setUsuario(null);
      history.push("/tabs/home");
    }
  }, [history, isAuthenticated, setAuthenticated, setUsuario]);
  return null;
};
export default Logout;
