import React, { useRef, useEffect ,useCallback} from 'react';
import { Location } from '../models/Location';

interface MapProps {
  locations: Location[]
  mapCenter: Location
}
 
const Map: React.FC<MapProps> = ({ mapCenter, locations }) => {
 const divRef = React.useRef<HTMLDivElement>(null);

  const map = useRef<google.maps.Map>();



  const Markers = useCallback(  function addMarkers() {
    locations.forEach((markerData) => {
      let infoWindow = new google.maps.InfoWindow({
        content: `<h6>${markerData.name} ${markerData.address}</h6>`
      });

      let marker = new google.maps.Marker({
        position: new google.maps.LatLng(markerData.lat, markerData.lng),
        map: map.current!,
        title: markerData.name
      });

      marker.addListener('click', () => {
        infoWindow.open(map.current!, marker);
      });
    });
  }, [locations]);

  useEffect(() => {
    
    if (null !== divRef.current) {
    map.current = new google.maps.Map(divRef.current, {
      center: {
        lat: -34.1707486,
        lng:-70.7457519
      },
      zoom: 16
    });

    Markers();

    google.maps.event.addListenerOnce(map.current, 'idle', () => {
      if (divRef.current) {
        divRef.current.classList.add('show-map');
      }
    });

  }
  }, [mapCenter, locations, Markers]);

  return (
    <div ref={divRef} className="map-canvas"></div>
  );
}

export default Map;