import React, { useState, useEffect } from "react";
import Firebase from "./firebase";

export const Document = React.createContext();
export const DocumentContext = ({ children }) => {
  const [documentsMiscellaneous, setDocumentsMiscellaneous] = useState([]);
  const [documentsForm, setDocumentsForm] = useState([]);
  const [documentsSalud, setDocumentsSalud] = useState([]);

  useEffect(() => {
    async function getDocuments() {
      let documentsRef = await Firebase.firestore().collection("documents");
      let miscellaneousDocuments = await documentsRef
        .where("tipoDocumento", "==", "Miscellaneous")
        .get();
      let miscellaneousDocumentsArray = [];
      miscellaneousDocuments.forEach(function (doc) {
        let d = doc.data();
        d.id = doc.id;
        d.key = doc.id;
        miscellaneousDocumentsArray.push(d);
      });
      setDocumentsMiscellaneous(miscellaneousDocumentsArray);
      let formDocuments = await documentsRef
        .where("tipoDocumento", "==", "Formulario")
        .get();
      let formDocumentsArray = [];
      formDocuments.forEach(function (doc) {
        let d = doc.data();
        d.id = doc.id;
        d.key = doc.id;
        formDocumentsArray.push(d);
      });

      setDocumentsForm(formDocumentsArray);

      let saludDocuments = await documentsRef
        .where("tipoDocumento", "==", "Salud")
        .get();
      let saludDocumentsArray = [];
      saludDocuments.forEach(function (doc) {
        let d = doc.data();
        d.id = doc.id;
        d.key = doc.id;
        saludDocumentsArray.push(d);
      });

      setDocumentsSalud(saludDocumentsArray);
    }
    getDocuments();
  }, []);

  return (
    <Document.Provider
      value={{
        documentsMiscellaneous,
        setDocumentsMiscellaneous,
        documentsForm,
        setDocumentsForm,
        documentsSalud,
        setDocumentsSalud,
      }}
    >
      {children}
    </Document.Provider>
  );
};
