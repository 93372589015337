import React, { useContext } from "react";
import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonPage,
  IonButtons,
  IonMenuButton,
  IonTitle,
  IonList,
  IonLabel,
  IonListHeader,
  IonItemDivider,
} from "@ionic/react";
import "./styles.scss";
import { Document } from "../../providers/Documents";
import { DownloadOutlined } from "@ant-design/icons";
import { Card } from "antd";
interface MiselaniunProps {}

const About: React.FC<MiselaniunProps> = () => {
  const { documentsMiscellaneous } = useContext(Document);
  console.log("documentsMiscellaneous", documentsMiscellaneous);

  return (
    <IonPage id="about-page" className="miscellaneous">
      <IonHeader translucent={true}>
        <IonToolbar className="HeaderPage">
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonTitle>Información Importante</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent forceOverscroll fullscreen>
        {documentsMiscellaneous &&
          documentsMiscellaneous.map(function (
            item: { title: string; files_multiple: any[]; body: string },
            index: number
          ) {
            return (
              <Card
                key={index}
                size="small"
                title={item.title}
                style={{ padding: 10, margin: 10 }}
              >
                <p>{item && item.body ? item.body : ""}</p>
                {item.files_multiple &&
                  item.files_multiple.map(function (d, idx) {
                    return (
                      <li key={idx}>
                        <a
                          href={d.src}
                          target="_blank"
                          rel="noopener noreferrer"
                          download={d.title}
                          title={d.title}
                          className="download"
                        >
                          <DownloadOutlined
                            style={{ fontSize: "16px", color: "#08c" }}
                          />
                          {d.title}
                        </a>
                      </li>
                    );
                  })}
              </Card>
            );
          })}
      </IonContent>
    </IonPage>
  );
};

export default React.memo(About);
