import React from "react";
import { Redirect, Route } from "react-router-dom";
import {
  IonApp,
  IonRouterOutlet,
  IonSplitPane,
  isPlatform,
  setupConfig,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import Menu from "./components/Menu";
import "@ionic/react/css/core.css";
import "./theme/variables.css";
import MainTabs from "./pages/MainTabs";
import { AuthContext } from "./providers/AuthContext";
import { DocumentContext } from "./providers/Documents";

import Account from "./pages/Account";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import About from "./pages/AboutUs";
import News from "./pages/News";
import Miscellaneous from "./pages/Miscellaneous";

import "bootstrap/dist/css/bootstrap.min.css";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles.scss";
import "antd/dist/antd.css";

const App: React.FC = () => {
  const getConfig = () => {
    let config = {
      swipeBackEnabled: false, // also prevent swiping back on either platform
      hardwareBackButton: false, // this is what you need (android only)
    };

    return config;
  };
  setupConfig(getConfig());

  return (
    <AuthContext>
      <DocumentContext>
        <IonicApp />
      </DocumentContext>
    </AuthContext>
  );
};

interface StateProps {}

interface IonicAppProps extends StateProps {}

const IonicApp: React.FC<IonicAppProps> = () => {
  return (
    <>
      <IonApp className="main-app">
        <IonReactRouter>
          <IonSplitPane contentId="main">
            <Menu />
            <IonRouterOutlet id="main">
              <Route path="/tabs" component={MainTabs} />
              <Route path="/account" component={Account} />
              <Route path="/login" component={Login} />
              <Route path="/signup" component={Signup} />
              <Route path="/about" component={About} />
              <Route path="/miscellaneous" component={Miscellaneous} />
              <Route path="/noticias/:id" component={News} />

              <Route
                path="/logout"
                render={() => {
                  return <Redirect to="/" />;
                }}
              />
              <Route
                render={() => {
                  return <Redirect to="/" />;
                }}
              />
              <Route path="/" component={MainTabs} exact />
            </IonRouterOutlet>
          </IonSplitPane>
        </IonReactRouter>
      </IonApp>
    </>
  );
};

export default App;
