import React, { useState, useEffect } from "react";
import Firebase from "./firebase";
import _get from "lodash/get";
async function NewsGetAll() {
  let newsArray = [];

  try {
    let newsRef = await Firebase.firestore().collection("news");
    let news = await newsRef.where("active", "==", true).get();

    news.forEach(function (doc) {
      let d = doc.data();
      if (!_get(d, "deleted", false)) {
        d.id = doc.id;
        d.key = doc.id;
        newsArray.push(d);
      }
    });
  } catch (e) {}

  return newsArray;
}

async function NewGet(key) {
  try {
    const newsRef = await Firebase.firestore().collection("news").doc(key);
    const document = await newsRef.get();
    if (document.exists) {
      const licitacionData = { ...document.data(), key };

      return licitacionData;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

export { NewsGetAll, NewGet };
