import React, { useState, useEffect } from "react";
import {
  IonButtons,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonBackButton,
} from "@ionic/react";
import "./styles.scss";
import { DownloadOutlined } from "@ant-design/icons";
import { NewGet } from "../../providers/news";
import { Carousel, Image } from "antd";
import { useParams } from "react-router-dom";
import _get from "lodash/get";

import parse from "html-react-parser";
interface NoticiasProps {}

const Noticias: React.FC<NoticiasProps> = (): JSX.Element => {
  const params = useParams();
  const IdNew = _get(params, "id", null);
  const [noticia, setNoticia] = useState([] as any);
  useEffect(() => {
    async function GetNew(id: any) {
      let data = await NewGet(id);
      console.log("noti", data);
      setNoticia(data);
    }
    GetNew(IdNew);
  }, [IdNew]);

  return (
    <IonPage id="Noticias-page" className="Noticias">
      <IonHeader>
        <IonToolbar className="HeaderPage">
          <IonButtons slot="start">
            <IonBackButton text="Volver" color="white"></IonBackButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent forceOverscroll fullscreen>
        <IonCard className="CardNews">
          <IonCardHeader>
            {noticia && (
              <div className="container">
                <div className="row">
                  <div className="col-sm-12  col-md-4">
                    <Carousel autoplay className="carrusel">
                      {noticia &&
                        noticia.images &&
                        noticia.images.map(
                          (imagesnoticia: {
                            image: {
                              src: string | undefined;
                              title: string | undefined;
                            };
                          }) => (
                            <div
                              className="imageContainer"
                              key={imagesnoticia.image.title}
                            >
                              <Image
                                className="images"
                                src={imagesnoticia.image.src}
                                alt={imagesnoticia.image.title}
                              ></Image>
                            </div>
                          )
                        )}
                    </Carousel>
                  </div>
                  <div className="col-sm-12 col-md-8">
                    <IonCardTitle className="CardTitle text-left">
                      <h4 className="text-left ">
                        {_get(noticia, "title", "")}
                      </h4>
                      <div className="description">
                        {parse(_get(noticia, "body", ""))}
                      </div>
                      {_get(noticia, "files_multiple", []).length > 0 && (
                        <div className="descargable">
                          <h6>Documentos descargables:</h6>

                          {_get(noticia, "files_multiple", []).map(function (
                            d: {
                              src: string | undefined;
                              title: {} | null | undefined;
                            },
                            idx: string | number | null | undefined
                          ) {
                            return (
                              <li key={idx}>
                                <a
                                  href={d.src}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  download={d.title}
                                  className="download"
                                >
                                  <DownloadOutlined
                                    style={{ fontSize: "16px", color: "#08c" }}
                                  />
                                  {d.title}
                                </a>
                              </li>
                            );
                          })}
                        </div>
                      )}
                    </IonCardTitle>
                  </div>
                </div>
              </div>
            )}
          </IonCardHeader>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default Noticias;
