import React, { useEffect, useState } from "react";
import Firebase from "./firebase";

export const Auth = React.createContext();

export const AuthContext = ({ children }) => {
  const [usuario, setUsuario] = useState(null);
  const [userBenefits, setUserBenefits] = useState([]);
  const [Benefits, setBenefits] = useState([]);

  const [isAuthenticated, setAuthenticated] = useState(false);

/*   useEffect(() => {
    Firebase.app()
      .auth()
      .onAuthStateChanged(function (user) {
        if (user) {
          setUsuario(user);
          setAuthenticated(true);
        }
      });
  }, []); */

  useEffect(() => {
    if (!isAuthenticated) {
      try {
        Firebase.auth()
          .signOut()
          .then(function () {
            // Sign-out successful.
          })
          .catch(function (error) {
            console.log(error);
          });
      } catch (error) {}
    }
  }, [isAuthenticated]);

  return (
    <Auth.Provider
      value={{
        usuario,
        isAuthenticated,
        setAuthenticated,
        setUsuario,
        userBenefits,
        setUserBenefits,
        Benefits,
        setBenefits,
      }}
    >
      {children}
    </Auth.Provider>
  );
};
