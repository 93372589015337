import React from "react";
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonButtons,
  IonMenuButton,
} from "@ionic/react";
import "./styles.scss";

import { Form, Input, Button, notification } from "antd";
import { UserOutlined } from "@ant-design/icons";
import firebase from "../../providers/firebase";
import { useHistory } from "react-router-dom";

interface ForgotPasswordProps {}

const ForgotPassword: React.FC<ForgotPasswordProps> = () => {
  const history = useHistory();

  const onFinish = async (values: any) => {
    if (values.email) {
      firebase
        .auth()
        .sendPasswordResetEmail(values.email)
        .then(function () {
          // Password reset email sent.
          console.log("Password send");
          notification["success"]({
            message: "Recuperación de Contraseña",
            description:
              "Se ha enviado un correo al email ingresado, por favor revise su correo y siga las indicaciones para recuperar su contraseña.",
          });
          history.push("/tabs/home", { direction: "none" });
        })
        .catch(function (error) {
          console.log(error);
          notification["error"]({
            message: "Recuperación de Contraseña",
            description:
              "No se ha encontrado registro del correo ingresado en el sistema",
          });
        });
    }
  };

  const [form] = Form.useForm();

  return (
    <IonPage id="ForgotPassword-page">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonTitle>Recuperar Contraseña</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="ForgotPassword-logo">
          <img src="assets/logo/logo.png" height="150" alt="Logo"></img>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <Form form={form} onFinish={onFinish} layout="vertical">
                <Form.Item
                  label="Ingrese su email para recuperar su contraseña"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message:
                        "Por favor ingrese su email para recuperar su contraseña",
                    },
                  ]}
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Ingrese su Email"
                    type="email"
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                  >
                    Recupear Contraseña
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ForgotPassword;
