import React from "react";
import { Table } from "antd";
import { Collapse } from "antd";

function humanizeNumber(n:any) {
  n=parseInt(n)
  n = n.toString()

  while (true) {
    let n2 = n.replace(/(\d)(\d{3})($|,|\.)/g, '$1.$2$3')
    if (n === n2) break
    n = n2
  }
  return n
}


const Benefit = () => {
  const { Panel } = Collapse;

  const callback = (key: any) => {};
  const uf=29075;
  const columns = [
    {
      title: "Fallecido",
      dataIndex: "Beneficiario",
    },
    {
      title: "UF",
      dataIndex: "UF",
    },
    {
      title: "Pesos",
      dataIndex: "Pesos",
    },
  ];
  const data = [
    {
      key: "1",
      Beneficiario: "Socio",
      UF: 42.9,
      Pesos: "$"+ humanizeNumber(42.9*uf),
    },
    {
      key: "2",
      Beneficiario: "Esposa",
      UF: 42.9,
      Pesos: "$"+ humanizeNumber(42.9*uf),
    },
    {
      key: "3",
      Beneficiario: "Hijos",
      UF: 23.8,
      Pesos: "$"+ humanizeNumber(23.8*uf),
    },
    {
      key: "4",
      Beneficiario: "Padres y Hermanos",
      UF: 9.9,
      Pesos: "$"+ humanizeNumber(9.9*uf),
    },
    {
      key: "5",
      Beneficiario: "Suegros",
      UF: 9.1,
      Pesos: "$"+ humanizeNumber(9.1*uf),
    },
    {
      key: "6",
      Beneficiario: "Hijo Nacido Muerto",
      UF: 4.9,
      Pesos:"$"+ humanizeNumber(4.9*uf),
    },
  ];

  const columnsGenerally = [
    {
      title: "Beneficio",
      dataIndex: "Beneficio",
    },
    {
      title: "UF",
      dataIndex: "UF",
    },
    {
      title: "Veces Anuales",
      dataIndex: "Veces",
    },
    {
      title: "Pesos",
      dataIndex: "Pesos",
    },
  ];
  const dataGenerally = [
    {
      key: "1",
      Beneficio: "Optica",
      UF: 1,
      Veces: 2,
      Pesos:"$"+ humanizeNumber(uf),
    },
    {
      key: "2",
      Beneficio: "Prótesis",
      UF: 1,
      Veces: 2,
      Pesos:"$"+ humanizeNumber(uf),
    },
    {
      key: "3",
      Beneficio: "Dental",
      UF: 1,
      Veces: 2,
      Pesos:"$"+ humanizeNumber(uf),
    },
    {
      key: "4",
      Beneficio: "Nacimiento",
      UF: 2,
      Veces: 2,
      Pesos:"$"+ humanizeNumber(2*uf),
    },
    {
      key: "5",
      Beneficio: "Beca Trabajador",
      UF: 3,
      Veces: 2,
      Pesos:"$"+ humanizeNumber(3*uf),
    },
  ];
  const columnsRetiro = [
    {
      title: "Años",
      dataIndex: "años",
    },
    {
      title: "UF",
      dataIndex: "UF",
    },

    {
      title: "Pesos",
      dataIndex: "Pesos",
    },
  ];
  const dataRetiro = [
    {
      key: "1",
      años: "<5",
      UF: 0,
      Pesos:"$"+ humanizeNumber(0*uf),
    },
    {
      key: "2",
      años: ">5<=10",
      UF: 14.78,
      Pesos:"$"+ humanizeNumber(14.78*uf),
},
    {
      key: "3",
      años: ">10<=15",
      UF: 19.71,
      Pesos:"$"+ humanizeNumber(19.71*uf),
    },
    {
      key: "4",
      años: ">15<=20",
      UF: 24.64,
      Pesos:"$"+ humanizeNumber(24.64*uf),
    },
    {
      key: "5",
      años: ">20<=25",
      UF: 34.49,
      Pesos:"$"+ humanizeNumber(34.49*uf),
    },
    {
      key: "6",
      años: ">25<=30",
      UF: 44.34,
      Pesos:"$"+ humanizeNumber(44.34*uf),
    },
    {
      key: "7",
      años: ">30<=35",
      UF: 54.2,
      Pesos:"$"+ humanizeNumber(54.2*uf),
    },
    {
      key: "8",
      años: ">35",
      UF: 64.02,
      Pesos:"$"+ humanizeNumber(64.02*uf),     },
  ];

  return (
    <div>
      <span>
        Beneficios calculados con UF diciembre 2020 <strong>29.075</strong>
      </span>
      <Collapse defaultActiveKey={["1"]} onChange={callback} accordion>
        <Panel header="Cuota Mortuoria" key="1">
          <h6>Cuota Mortuoria</h6>

          <Table columns={columns} dataSource={data} pagination={false} />
        </Panel>
        <Panel header="Beneficios Generales" key="2">
          <h6>Beneficios Generales</h6>

          <Table
            columns={columnsGenerally}
            dataSource={dataGenerally}
            pagination={false}
          />
        </Panel>
        <Panel header="Valores Couta Retiro Socio 2020" key="3">
          <h6>Valores Couta Retiro Socio 2020</h6>

          <Table
            columns={columnsRetiro}
            dataSource={dataRetiro}
            pagination={false}
          />
        </Panel>
      </Collapse>
    </div>
  );
};
export default Benefit;
