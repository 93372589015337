const iconPath = '/assets/weather';

export default {
    '01d': `${iconPath}/clear.png`,
    '01n': `${iconPath}/clearnight.png`,
    '02d': `${iconPath}/partlycloudy.png`,
    '02n': `${iconPath}/partlycloudynight.png`,
    '03d': `${iconPath}/cloudy.png`,
    '03n': `${iconPath}/cloudynight.png`,
    '04d': `${iconPath}/hazy.png`,
    '04n': `${iconPath}/hazy.png`,
    '09d': `${iconPath}/flurries.png`,
    '09n': `${iconPath}/flurries.png`,
    '10d': `${iconPath}/rain01.png`,
    '10n': `${iconPath}/rainnight.png`,
    '11d': `${iconPath}/thunderstorms02.png`,
    '11n': `${iconPath}/thunderstorms02.png`,
    '13d': `${iconPath}/snow.png`,
    '13n': `${iconPath}/snownight.png`,
    '50d': `${iconPath}/fog.png`,
    '50n': `${iconPath}/fog.png`,
};