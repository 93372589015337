import React from "react";
import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonPage,
  IonButtons,
  IonMenuButton,
  IonTitle,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
} from "@ionic/react";
import "./styles.css";
interface AboutProps {}

const About: React.FC<AboutProps> = () => {
  return (
    <IonPage id="about-page">
      <IonHeader translucent={true}>
        <IonToolbar className="HeaderPage">
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonTitle>Quienes Somos</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent forceOverscroll fullscreen>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>VISIÓN</IonCardTitle>
          </IonCardHeader>

          <IonCardContent>
            Una organización gremial autónoma, solidaria, que entrega
            oportunidades al desarrollo libre, participativa, unitaria y
            responsable de sus asociados y asociadas, su grupo familiar y su
            real integración hacia la comunidad.
          </IonCardContent>
          <IonCardHeader>
            <IonCardTitle>MISIÓN</IonCardTitle>
          </IonCardHeader>

          <IonCardContent>
            Representar los derechos de sus afiliados y afiliadas, velando por
            el cumplimiento de la legislación, propendiendo y promoviendo la
            capacitación, comunicación y crecimiento institucional, para generar
            los cambios profundos en el mundo del trabajo.
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default React.memo(About);
