import firebase from "firebase";
import "firebase/app";
var firebaseConfig = {
  apiKey: "AIzaSyCEgkWtn92XYwkMbJfwe4FZoT6Ho4O8uxc",
  authDomain: "sindicato-caletones.firebaseapp.com",
  databaseURL: "https://sindicato-caletones.firebaseio.com",
  projectId: "sindicato-caletones",
  storageBucket: "sindicato-caletones.appspot.com",
  messagingSenderId: "230765309632",
  appId: "1:230765309632:web:cc84b752015bb1791c8725",
  measurementId: "G-ZEPGNX1NLD",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
export default firebase;
