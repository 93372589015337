import React, { useContext, useEffect } from "react";
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonButtons,
  IonMenuButton,
} from "@ionic/react";
import { connect } from "../../data/connect";
import "./styles.scss";
import { Row, Col } from "antd";

import { Tabs } from "antd";
import { UnorderedListOutlined, SmileTwoTone } from "@ant-design/icons";
import Mortuoria from "./benefits/index";
import MyBenefit from "./MyBenefits";
import { Auth } from "../../providers/AuthContext";
import { useHistory } from "react-router-dom";

interface OwnProps {}

interface StateProps {}

interface DispatchProps {}

interface SpeakerListProps extends OwnProps, StateProps, DispatchProps {}

const Directory: React.FC<SpeakerListProps> = () => {
  const { TabPane } = Tabs;
  const history = useHistory();
  const { isAuthenticated } = useContext(Auth);

  useEffect(() => {
    if (!isAuthenticated) {
      history.push("/tabs/home");
    }
  }, [history, isAuthenticated]);
  return (
    <IonPage id="MyProfile" className="myprofile">
      <IonHeader translucent={true}>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonTitle>MI SINDICATO</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <Row>
          <Col span={24}>
            <Tabs defaultActiveKey="1">
              <TabPane
                tab={
                  <span>
                    <UnorderedListOutlined />
                    Beneficios
                  </span>
                }
                key="1"
              >
                <Mortuoria />
              </TabPane>
              <TabPane
                tab={
                  <span>
                    <SmileTwoTone twoToneColor="#eb2f96" />
                    Mis Beneficios
                  </span>
                }
                key="2"
              >
                <MyBenefit></MyBenefit>
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: () => ({}),
  component: React.memo(Directory),
});
