import React, { useEffect, useState } from "react";
import {
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
} from "@ionic/react";
import { connect } from "../../data/connect";
import "./styles.scss";
import WeatherWidget from "../../components/Weather/WeatherWidget";
import { Link } from "react-router-dom";
import { NewsGetAll } from "../../providers/news";
import { Carousel, Image, Button } from "antd";
import { ReadOutlined } from "@ant-design/icons";
import parse from "html-react-parser";
import _get from "lodash/get";
interface OwnProps {}

interface StateProps {}

interface DispatchProps {}

interface SpeakerListProps extends OwnProps, StateProps, DispatchProps {}

const Home: React.FC<SpeakerListProps> = () => {
  let id = 1;

  const [news, setNews] = useState([] as any);

  const [forecast, setforecast] = useState(null);
  useEffect(() => {
    async function Weather() {
      const weatherURL = `https://api.openweathermap.org/data/2.5/forecast?units=metric&q=Rancagua,CL&lang=es&APPID=bc12351609cec3cfd50193b0cd0979d6`;
      let response = await fetch(weatherURL);
      let forecast = await response.json();
      let dato = forecast.list.map(
        (data: {
          dt: any;
          main: { temp: any; temp_min: any; temp_max: any; humidity: any };
          weather: { icon: any; description: any }[];
          clouds: { all: any };
          wind: { speed: any };
        }) => ({
          dt: data.dt,
          temp: data.main.temp,
          temp_min: data.main.temp_min,
          temp_max: data.main.temp_max,
          humidity: data.main.humidity,
          icon: data.weather[0].icon,
          desc: data.weather[0].description,
          clouds: data.clouds.all,
          wind: data.wind.speed,
        })
      );
      setforecast(dato);
    }

    Weather();
  }, []);

  useEffect(() => {
    async function GetNews() {
      let newsArray = await NewsGetAll();
      setNews(newsArray);
    }
    GetNews();
  }, []);

  return (
    <IonPage id="Home" className="Home">
      <IonHeader translucent={true}>
        <IonToolbar className="HeaderPage">
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonTitle>Noticias del Sindicato</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen={false}>
        <div className="container">
          <IonCard>
            {forecast && (
              <WeatherWidget
                config={{
                  location: "Rancagua,CL",
                  unit: "metric",
                  locale: "es-ES",
                }}
                forecast={forecast}
              />
            )}
          </IonCard>
          {news.map(function (
            item: any,
            index: string | number | null | undefined
          ) {
            return (
              <IonCard key={index} className="CardNews">
                <IonCardHeader>
                  <div className="container">
                    <div className="row">
                      <div className="col-sm-12  col-md-4">
                        <Carousel autoplay className="carrusel">
                          {item.images &&
                            item.images.map(
                              (imagesItem: {
                                image: {
                                  src: string | undefined;
                                  title: string | undefined;
                                };
                              }) => (
                                <div
                                  className="imageContainer"
                                  key={imagesItem.image.title}
                                >
                                  <Image
                                    className="images"
                                    src={imagesItem.image.src}
                                    alt={imagesItem.image.title}
                                  ></Image>
                                </div>
                              )
                            )}
                        </Carousel>
                      </div>
                      <div className="col-sm-12 col-md-8">
                        <IonCardTitle className="CardTitle text-left">
                          <h5 className="text-center ">{item.title}</h5>
                          <div className="description">
                            {parse(_get(item, "body", ""))}{" "}
                          </div>
                          <div className="button">
                            <Link to={`/tabs/noticias/${item.key}`}>
                              <Button
                                type="primary"
                                className="readMore"
                                icon={<ReadOutlined />}
                              >
                                Leer Más...
                              </Button>
                            </Link>
                          </div>
                        </IonCardTitle>
                      </div>
                    </div>
                  </div>
                </IonCardHeader>
              </IonCard>
            );
          })}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: () => ({}),
  component: React.memo(Home),
});
