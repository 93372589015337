import React, { useContext } from "react";
import { RouteComponentProps, withRouter, useLocation } from "react-router";

import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
} from "@ionic/react";
import {
  logIn,
  person,
  personAdd,
  library,
  medkitOutline,
  peopleCircleOutline,
  bookOutline,
} from "ionicons/icons";
import { connect } from "../data/connect";
import { Auth } from "../providers/AuthContext";

import "./Menu.css";

const routes = {
  loggedInPages: [
    { title: "Inicio", path: "/tabs/home", icon: person },
    { title: "Mi Sindicato", path: "/tabs/myprofile", icon: person },
    { title: "Salir", path: "/tabs/logout", icon: person },
  ],
  loggedOutPages: [
    { title: "Inicio", path: "/tabs/home", icon: person },
    { title: "Ingreso", path: "/tabs/login", icon: logIn },
    { title: "Recuperar Contraseña", path: "/tabs/forgot", icon: logIn },

    // { title: 'Soporte', path: '/support', icon: help },
    { title: "Registrarme", path: "/tabs/signup", icon: personAdd },
  ],

  OtherPages: [
    { title: "Quienes Somos", path: "/tabs/about", icon: person },
    {
      title: "Miscelaneos",
      path: "/tabs/miscellaneous",
      icon: bookOutline,
    },
    { title: "Formularios", path: "/tabs/formularios", icon: library },
    { title: "Salud", path: "/tabs/salud", icon: medkitOutline },
    {
      title: "Comisiones",
      path: "/tabs/comisiones",
      icon: peopleCircleOutline,
    },
  ],
};

interface Pages {
  title: string;
  path: string;
  icon: string;
  routerDirection?: string;
}
interface StateProps {
  menuEnabled: boolean;
}

interface MenuProps extends RouteComponentProps, StateProps {}

const Menu: React.FC<MenuProps> = ({ menuEnabled }) => {
  const { isAuthenticated } = useContext(Auth);
  const location = useLocation();
  function renderlistItems(list: Pages[]) {
    return list
      .filter((route) => !!route.path)
      .map((p) => (
        <IonMenuToggle key={p.title} auto-hide="false">
          <IonItem
            detail={false}
            routerLink={p.path}
            routerDirection="none"
            className={
              location.pathname.startsWith(p.path) ? "selected" : undefined
            }
          >
            <IonIcon slot="start" icon={p.icon} />
            <IonLabel>{p.title}</IonLabel>
          </IonItem>
        </IonMenuToggle>
      ));
  }

  return (
    <IonMenu type="overlay" disabled={!menuEnabled} contentId="main">
      <IonContent forceOverscroll={false}>
        {renderlistItems(routes.OtherPages)}

        <IonList lines="none">
          <IonListHeader>Mi Cuenta</IonListHeader>
          {isAuthenticated
            ? renderlistItems(routes.loggedInPages)
            : renderlistItems(routes.loggedOutPages)}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    menuEnabled: state.data.menuEnabled,
  }),

  component: withRouter(Menu),
});
