import React from "react";
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonButtons,
  IonMenuButton,
} from "@ionic/react";
import { connect } from "../../data/connect";
import {
  IonList,
  IonItem,
  IonLabel,
  IonAvatar,
  IonIcon,
  IonItemDivider,
} from "@ionic/react";
import { call } from "ionicons/icons";
import "./styles.scss";
import { FaLink, FaClinicMedical, FaFileMedicalAlt, FaNotesMedical, FaUserMd, FaUserInjured, FaHospital, FaHospitalAlt, FaUser } from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";
import { GrSchedule, GrScheduleNew } from "react-icons/gr";
import { BsFillPersonLinesFill } from "react-icons/bs";
import { GiMedicinePills, GiMedicalThermometer, GiMiracleMedecine, GiClick, } from "react-icons/gi";
import { FiPhoneCall } from "react-icons/fi"

interface OwnProps { }

interface StateProps { }

interface DispatchProps { }

interface SpeakerListProps extends OwnProps, StateProps, DispatchProps { }

const Directory: React.FC<SpeakerListProps> = () => {
  return (
    <IonPage id="Directory">
      <IonHeader translucent={true}>
        <IonToolbar className="HeaderPage">
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonTitle>Directorio</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        {/*-- List of Text Items --*/}
        <IonList>
          <IonItemDivider>
            <IonLabel className='titleDirectory'>Codelco</IonLabel></IonItemDivider>
          <h1 className='Subtitle1'> Contraloría </h1>
          <IonItem>
            <FaUser size={'2rem'}></FaUser>
            <IonLabel className='listDirectory'>
              <h4>
                Violeta Gómez</h4>
              <p>
                <a href="mailto:Vgomez@codelco.cl" title=""  target="_blank" rel="noopener noreferrer">

                  Vgomez@codelco.cl
                </a>
              </p>
            </IonLabel>
          </IonItem>
          <IonItem>
            <FaUser size={'2rem'}></FaUser>
            <IonLabel className='listDirectory'>


              <h4>
                Rubén Alarcón</h4>
              <p>
                <a href="mailto:Ralarcon@codelco.cl" title=""  target="_blank" rel="noopener noreferrer">

                  Ralarcon@codelco.cl
                </a>
              </p>
            </IonLabel>
          </IonItem>
          <IonItem>
            <FaUser size={'2rem'}></FaUser>
            <IonLabel className='listDirectory'>
              <h4>
                Cristian Ramírez</h4>
              <p>
                <a href="mailto:Crami019@codelco.cl" title=""  target="_blank" rel="noopener noreferrer">
                  Crami019@codelco.cl
                </a>
              </p>
            </IonLabel>
          </IonItem>
          <h1  className='Subtitle1'> Oficina del Personal  </h1>
          <h4  className='Subtitle2'> Asistentes Sociales  </h4>

          <IonItem>
            <FaUser size={'2rem'}></FaUser>
            <IonLabel className='listDirectory'>
              <h4>
              Shannon Ahumada Henríquez</h4>
              <p>
                <a href="mailto:Sahum003@codelco.cl" title=""  target="_blank" rel="noopener noreferrer">
                Sahum003@codelco.cl 
                </a>
              </p>
            </IonLabel>
          </IonItem>
          <IonItem>
            <FaUser size={'2rem'}></FaUser>
            <IonLabel className='listDirectory'>
              <h4>
              Karem Parra Sanhueza</h4>
              <p>
                <a href="mailto:Kparra@codelco.cl" title=""  target="_blank" rel="noopener noreferrer">
                Kparra@codelco.cl  
                </a>
              </p>
            </IonLabel>
          </IonItem>
          <h4  className='Subtitle2'>Atención de Personal (beneficios convenio colectivo)   </h4>

<IonItem>
  <FaUser size={'2rem'}></FaUser>
  <IonLabel className='listDirectory'>
    <h4>
    Elena Reyes Espina.  (Licencias Médicas)  </h4>
    <p>
      <a href="mailto:Ereyes@codelco.cl" title=""  target="_blank" rel="noopener noreferrer">
      Ereyes@codelco.cl
      </a>
    </p>
  </IonLabel>
</IonItem>
<IonItem>
  <FaUser size={'2rem'}></FaUser>
  <IonLabel className='listDirectory'>
    <h4>
    María Alejandra Hernández. </h4>
    <p>
      <a href="mailto:Mhernand@codelco.cl" title=""  target="_blank" rel="noopener noreferrer">
      Mhernand@codelco.cl 
      </a>
    </p>
  </IonLabel>
</IonItem>
<h4  className='Subtitle2'>Firma digital y gestión SAP</h4>

<IonItem>
  <FaUser size={'2rem'}></FaUser>
  <IonLabel className='listDirectory'>
    <h4>
    Juan Pablo Colipi Morales    </h4>
    <p>
      <a href="mailto:jcolipi@codelco.cl" title=""  target="_blank" rel="noopener noreferrer">
      Jcolipi@codelco.cl
      </a>
    </p>
  </IonLabel>
</IonItem>
<IonItem>
  <FaUser size={'2rem'}></FaUser>
  <IonLabel className='listDirectory'>
    <h4>
    Miguel Miranda Miranda</h4>
    <p>
      <a href="mailto:mira008@codelco.cl" title="" target="_blank" rel="noopener noreferrer">
      Mira008@codelco.cl 
      </a>
    </p>
  </IonLabel>
</IonItem>
          <IonItemDivider>
            <IonLabel className='titleDirectory'>Isalud - Isapre de Codelco</IonLabel>
          </IonItemDivider>
          <IonItem>
            <GiClick size={'2rem'}></GiClick>
            <IonLabel className='listDirectory'>
              <p>
                <a href="https://www.isapredecodelco.cl/SucursalVirtual/Authentication/Login?iid=63&tuid=1" title=""  target="_blank" rel="noopener noreferrer">
                  Sucursal Virtual
                </a>
              </p>
            </IonLabel>
          </IonItem>
          <IonItem>
            <FiPhoneCall size={'2rem'}></FiPhoneCall>
            <IonLabel className='listDirectory'>
              <h4> Call-Center</h4>
              <p>
                <a href="tel:800800331" title=""  target="_blank" rel="noopener noreferrer">

                  800 800 331
                </a>
              </p>
            </IonLabel>
          </IonItem>
          <IonItem>
            <FiPhoneCall size={'2rem'}></FiPhoneCall>
            <IonLabel className='listDirectory'>
              <h4> 800 Médico</h4>
              <p>
                <a href="tel:800800331" title=""  target="_blank" rel="noopener noreferrer">

                  800 663 426
                </a>
              </p>
              <p>Orientación Médica las 24 hrs.
              </p>
            </IonLabel>
          </IonItem>
          <IonItemDivider>
            <IonLabel className='titleDirectory'>Fusat</IonLabel>
          </IonItemDivider>
          <IonItem>
            <GrScheduleNew size={'2rem'} />
            <IonLabel className='listDirectory'>

              <p>
                <a href="http://agenda.fusat.cl/" title=""  target="_blank" rel="noopener noreferrer">
                  Reserva de Horas Online


                </a>
              </p>

            </IonLabel>
          </IonItem>
          <IonItem>
            <GrSchedule size={'2rem'}></GrSchedule>
            <IonLabel className='listDirectory'>
              <h4> Anular Horas</h4>
              <p>
                <a href="tel:+5672204301" title=""  target="_blank" rel="noopener noreferrer">

                  (72) 2204301
                </a>
              </p>
            </IonLabel>
          </IonItem>
          <IonItem>
            <BsFillPersonLinesFill size={'2rem'}></BsFillPersonLinesFill>
            <IonLabel className='listDirectory'>
              <h4>&nbsp;Información de Pacientes</h4>
              <p>
                <a href="tel:+56722844201" title=""  target="_blank" rel="noopener noreferrer">
                  (72) 2844201
                </a>
              </p>
            </IonLabel>
          </IonItem>
          <IonItem>
            <FaUserMd size={'2rem'}></FaUserMd>
            <IonLabel className='listDirectory'>
              <h4>&nbsp;Centro Médico</h4>
              <p>
                <a href="tel:+5672204201" title=""  target="_blank" rel="noopener noreferrer">

                  (72) 2204201
                </a>
              </p>
            </IonLabel>
          </IonItem>
          <IonItem>
            <FaHospitalAlt size={'2rem'}></FaHospitalAlt>
            <IonLabel className='listDirectory'>
              <h4>&nbsp;Urgencia</h4>
              <p>
                <a href="tel:+56722204000" title=""  target="_blank" rel="noopener noreferrer">

                  (72) 2204000
                </a>
              </p>
            </IonLabel>
          </IonItem>
          <IonItem>
            <FaHospital size={'2rem'}></FaHospital>
            <IonLabel className='listDirectory'>
              <h4>&nbsp;Hospital</h4>
              <p>
                <a href="tel:+5672204201" title=""  target="_blank" rel="noopener noreferrer">

                  (72) 2204201
                </a>
              </p>
            </IonLabel>
          </IonItem>
          <IonItem>
            <FaFileMedicalAlt size={'2rem'}></FaFileMedicalAlt>
            <IonLabel className='listDirectory'>
              <h4>&nbsp;Imagenología</h4>
              <p>
                <a href="tel:+56722204019" title=""  target="_blank" rel="noopener noreferrer">

                  (72) 2204019
                </a>
              </p>
              <p>
                <a href="tel:+56722204343" title=""  target="_blank" rel="noopener noreferrer">

                  (72) 2204343
                </a>
              </p>
            </IonLabel>
          </IonItem>
          <IonItem>
            <GiMiracleMedecine size={'2rem'}></GiMiracleMedecine>
            <IonLabel className='listDirectory'>
              <h4>&nbsp;Toma de Muestras</h4>
              <p>
                <a href="tel:+56722204107" title=""  target="_blank" rel="noopener noreferrer">

                  (72) 2204107
                </a>
              </p>

            </IonLabel>
          </IonItem>
          <IonItem>
            <FaUserInjured size={'2rem'}></FaUserInjured>
            <IonLabel className='listDirectory'>
              <h4>&nbsp;Medicina Física y Rehabilitación</h4>
              <p>
                <a href="tel:+56722204095" title=""  target="_blank" rel="noopener noreferrer">

                  (72) 2204095
                </a>
              </p>

            </IonLabel>
          </IonItem>
          <IonItem>
            <FaNotesMedical size={'2rem'}></FaNotesMedical>
            <IonLabel className='listDirectory'>
              <h4>&nbsp;Cirugía Mayor Ambulatoria</h4>
              <p>
                <a href="tel:+56722204268" title=""  target="_blank" rel="noopener noreferrer">

                  (72) 2204268
                </a>
              </p>

            </IonLabel>
          </IonItem>
          <IonItem>
            <GiMiracleMedecine size={'2rem'}></GiMiracleMedecine>
            <IonLabel className='listDirectory'>
              <h4>&nbsp;Laboratorio Clínico</h4>
              <p>
                <a href="tel:+56722204016" title=""  target="_blank" rel="noopener noreferrer">

                  (72) 2204016
                </a>
              </p>

            </IonLabel>
          </IonItem>
          <IonItem>
            <GiMiracleMedecine size={'2rem'}></GiMiracleMedecine>
            <IonLabel className='listDirectory'>
              <h4>&nbsp;Banco de Sangre</h4>
              <p>
                <a href="tel:+56722205296" title=""  target="_blank" rel="noopener noreferrer">

                  (72) 2205296
                </a>
              </p>

            </IonLabel>
          </IonItem>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: () => ({}),
  component: React.memo(Directory),
});
