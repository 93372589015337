import React from "react";
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonButtons,
  IonMenuButton,
} from "@ionic/react";
import "./styles.scss";

import { Form, Input, Button, notification } from "antd";
import firebase from "../../providers/firebase";
import _get from "lodash/get";
import { useHistory } from "react-router-dom";

interface SignupProps {}

const Signup: React.FC<SignupProps> = () => {
  const [form] = Form.useForm();
  const history = useHistory();

  const onFinish = async (values: any) => {
    try {
      const response = await firebase
        .auth()
        .createUserWithEmailAndPassword(values.email, values.password);
      let uid = _get(response, "user.uid", "");
      delete values.password;
      firebase.firestore().collection("users").doc(uid).set(values);

      notification["success"]({
        message: "Registro",
        description:
          "Se ha realizado el registro correctamente, para poder visualizar toda su información su registro debe ser validado por el secretario del sindicato.",
      });
      history.push("/tabs/home", { direction: "none" });
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <IonPage id="signup-page">
      <IonHeader>
        <IonToolbar className="HeaderPage">
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonTitle>REGISTRO</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="register-logo">
          <img src="assets/logo/logo.png" height="150" alt="Sindicato logo" />
        </div>
        <div className="container">
          <Form
            form={form}
            name="registro"
            onFinish={onFinish}
            layout="vertical"
          >
            <Form.Item
              name="email"
              label="Email"
              rules={[
                { required: true, message: "Por favor ingrese su email!" },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="password"
              label="Contraseña"
              rules={[
                {
                  required: true,
                  message:
                    "Por favor ingrese la contraseña que usara para ingresar a Mi Sindicato!",
                },
              ]}
            >
              <Input type="password" />
            </Form.Item>
            <Form.Item
              name="name"
              label="Nombre"
              rules={[
                { required: true, message: "Por favor ingrese su nombre!" },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="lastName"
              label="Apellido"
              rules={[
                { required: true, message: "Por favor ingrese su apellido!" },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="rut"
              label="Rut"
              rules={[{ required: true, message: "Por favor ingrese su rut!" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="numberRol"
              label="Sap"
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese su número de sap!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Registrarse
              </Button>
            </Form.Item>
          </Form>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Signup;
