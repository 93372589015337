import React from "react";
import {
  IonTabs,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonLabel,
} from "@ionic/react";
import { Route, Redirect } from "react-router";
import {
  home,
  location,
  informationCircle,
  people,
  call,
} from "ionicons/icons";
import HomePage from "./Home";
import LeadersPage from "./Leaders";
import DirectoryPage from "./Directory";
import ContactPage from "./Contact";
import AboutPage from "./AboutUs";
import MyProfilePage from "./MyProfile";
import LoginPage from "./Login";
import SignupPage from "./Signup";
import ForgotPage from "./ForgotPassword";
import LogoutPage from "./Logout";
import MiscellaneousPage from "./Miscellaneous";
import FormulariosPage from "./Formularios";
import ComisionesPage from "./Comisiones";
import SaludPage from "./Salud";
import NoticiasPage from "./News";
interface MainTabsProps {}

const MainTabs: React.FC<MainTabsProps> = () => {
  return (
    <IonTabs>
      <IonRouterOutlet>
        <Route path="/tabs" render={() => <Redirect to="/tabs/home" />} exact />
        <Route
          path="/tabs/:tab(home)"
          render={() => <HomePage />}
          exact={true}
        />
        <Route path="/tabs/:tab(leaders)" render={() => <LeadersPage />} />
        <Route path="/tabs/:tab(directory)" render={() => <DirectoryPage />} />
        <Route path="/tabs/:tab(contact)" render={() => <ContactPage />} />
        <Route path="/tabs/:tab(about)" render={() => <AboutPage />} />
        <Route
          path="/tabs/:tab(miscellaneous)"
          render={() => <MiscellaneousPage />}
        />
        <Route path="/tabs/:tab(myprofile)" render={() => <MyProfilePage />} />
        <Route path="/tabs/:tab(login)" render={() => <LoginPage />} />
        <Route path="/tabs/:tab(signup)" render={() => <SignupPage />} />
        <Route path="/tabs/:tab(forgot)" render={() => <ForgotPage />} />
        <Route path="/tabs/:tab(logout)" render={() => <LogoutPage />} />
        <Route
          path="/tabs/:tab(formularios)"
          render={() => <FormulariosPage />}
        />
        <Route
          path="/tabs/:tab(comisiones)"
          render={() => <ComisionesPage />}
        />
        <Route path="/tabs/:tab(salud)" render={() => <SaludPage />} />
        <Route
          path="/tabs/:tab(noticias)/:id"
          render={() => <NoticiasPage />}
        />
        <Route
          render={() => {
            return <Redirect to="/tabs" />;
          }}
        />
      </IonRouterOutlet>

      <IonTabBar slot="bottom">
        <IonTabButton tab="home" href="/tabs/home">
          <IonIcon icon={home} />
          <IonLabel className="bnmenu">Noticias</IonLabel>
        </IonTabButton>
        <IonTabButton tab="leaders" href="/tabs/leaders">
          <IonIcon icon={people} />
          <IonLabel className="bnmenu">Dirigentes</IonLabel>
        </IonTabButton>
        <IonTabButton tab="directory" href="/tabs/directory">
          <IonIcon icon={call} />
          <IonLabel className="bnmenu">Directorio</IonLabel>
        </IonTabButton>
        <IonTabButton tab="contact" href="/tabs/contact">
          <IonIcon icon={location} />
          <IonLabel className="bnmenu">Contacto</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export default MainTabs;
