import React, { useContext, useState, useEffect } from "react";
import { Table } from "antd";
import { Auth } from "../../../providers/AuthContext";
import moment from "moment";
import "./styles.scss";
const MyBenefit = () => {
  const {
    usuario,
    userBenefits,
    Benefits,
  } = useContext(Auth);


  const [info, setInfo] = useState([]);

  const columnsGenerally = [
    {
      title: "Tipo Beneficio",
      dataIndex: "tipoBeneficio",
    },
    {
      title: "Item",
      dataIndex: "benefit",
    },
    {
      title: "Fecha",
      dataIndex: "fecha",
    },

    {
      title: "Registrado por",
      dataIndex: "updatedby",
    },
  ];

  useEffect(
    function () {
      let b = userBenefits.map(function (
        item: {
          benefit: any;
          createdate: { seconds: number };
          fecha: string;
          tipoBeneficio: string;
          typeBenefits: string;
        }      ) {
        let newitem = item;
        let name = Benefits.find((x: { id: any }) => x.id === item.benefit)
          .name;
        newitem.benefit = name;
        newitem.tipoBeneficio =
          item.typeBenefits === "cm"
            ? "Cuota Mortuoria"
            : item.typeBenefits === "bg"
            ? "Beneficio General"
            : "Couta Retiro";

        let fecha = new Date(item.createdate.seconds * 1000);
        newitem.fecha = moment(fecha).format("DD/MM/YYYY");

        return newitem;
      });
      setInfo(b);
    },
    [Benefits, userBenefits]
  );
  console.log(info);

  return (
    <div className="MyBenefit">
      <span>
        Beneficios entregados al trabajador {usuario.name} {usuario.lastName}
      </span>

      <Table columns={columnsGenerally} dataSource={info} pagination={false} />
    </div>
  );
};
export default MyBenefit;
