import React, { useState, useEffect } from "react";
import {
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonMenuButton,
  IonPage,
  IonSlide,
  IonSlides,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { connect } from "../../data/connect";
import { call, logoWhatsapp, mail, timer, library } from "ionicons/icons";
import "./styles.css";
import { LeadersGetAll } from "../../providers/leaders";
import _get from "lodash/get";
import Slider from "react-slick";

interface OwnProps {}

interface StateProps {}

interface DispatchProps {}

interface SpeakerListProps extends OwnProps, StateProps, DispatchProps {}

const Leaders: React.FC<SpeakerListProps> = () => {
  const [leaders, setLeaders] = useState([] as any);
  useEffect(() => {
    async function GetNews() {
      let leadersArray = await LeadersGetAll();
      setLeaders(leadersArray);
    }
    GetNews();
  }, []);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <IonPage id="Home">
      <IonHeader translucent={true}>
        <IonToolbar className="HeaderPage">
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonTitle>Dirigentes</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen={true}>
        <IonGrid fixed>
          {leaders && (
            <Slider {...settings}>
              {leaders.map(function (item: any, index: number) {
                return (
                  <div className={"DirigentesCard"} key={index}>
                    <IonCard className={"DirigentesCard"}>
                      <img
                        className="imageLeaders"
                        alt=""
                        src={_get(item, "image", "")}
                      />
                      <IonCardHeader>
                        <IonCardTitle className="leadersTitle">
                          {_get(item, "name", "")}
                        </IonCardTitle>
                        <IonCardSubtitle className="leadersSubtitile">
                          {_get(item, "position", "")}
                        </IonCardSubtitle>
                      </IonCardHeader>

                      <IonCardContent>
                        <IonItem>
                          <IonIcon icon={call} slot="start" color="primary" />
                          <IonLabel className="labelLeaders">
                            (072) 223 00 59 – 2230150
                          </IonLabel>
                        </IonItem>
                        <IonItem>
                          <IonIcon icon={mail} slot="start" />
                          <IonLabel className="labelLeaders">
                            <a href="mailto:sclavero@codelco.cl">
                              {_get(item, "email", "")}
                            </a>
                          </IonLabel>
                        </IonItem>
                        <IonItem>
                          <IonIcon icon={library} slot="start" />
                          <IonLabel className="labelLeaders">
                            <strong>Comisiones:</strong>

                            {_get(item, "commissions", []).map(function (
                              c: any,
                              i: number
                            ) {
                              return <li key={i}>{c}</li>;
                            })}
                          </IonLabel>
                        </IonItem>
                        <IonItem>
                          <IonIcon icon={library} slot="start" />
                          <IonLabel className="labelLeaders">
                            <strong>Comisiones Remplazante:</strong>

                            {_get(item, "replacement_commissions", []).map(
                              function (c: any, i: number) {
                                return <li key={i}>{c}</li>;
                              }
                            )}
                          </IonLabel>
                        </IonItem>
                        <IonItem>
                          <IonIcon
                            icon={logoWhatsapp}
                            slot="start"
                            color="success"
                          />
                          <IonLabel className="labelLeaders">
                            <a
                              href={`https://wa.me/${_get(
                                item,
                                "celular",
                                ""
                              )}`}
                            >
                              +{_get(item, "celular", "")}
                            </a>
                          </IonLabel>
                        </IonItem>
                        <IonItem>
                          <IonIcon
                            icon={timer}
                            slot="start"
                            color="instagram"
                          />
                          <IonLabel className="schedule">
                          {_get(item, "attention_schedule", "")}
                          </IonLabel>
                        </IonItem>
                      </IonCardContent>
                    </IonCard>
                  </div>
                );
              })}
            </Slider>
          )}
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state: any) => ({}),
  component: React.memo(Leaders),
});
