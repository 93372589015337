import React, { useState, useEffect } from "react";
import Firebase from "./firebase";

async function LeadersGetAll() {
  let LeadersArray = [];

  try {
    let LeadersRef = await Firebase.firestore().collection("leaders");
    let Leaders = await LeadersRef.get();

    Leaders.forEach(function (doc) {
      let d = doc.data();
      d.id = doc.id;
      d.key = doc.id;
      LeadersArray.push(d);
    });
  } catch (e) {}

  return LeadersArray;
}

export { LeadersGetAll };
